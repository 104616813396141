.Login_section__pJMoL {
    border-radius: 4px;
    width: calc(100%);
}

.Login_width80__mjD_T {
    width: calc(80%);
}

.Login_container__O9mdP {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.Login_left__FeBeZ {
    flex: 1 1;
    height: 100%;
    background: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login_left__FeBeZ img {
        width: 200px;
        -o-object-fit: contain;
           object-fit: contain;
        max-height: 400px;
    }

.Login_right__4fVzN {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1
}

@media screen and (min-width: 420px) {

.Login_right__4fVzN {
        padding: 0 40px
}
    }

.Login_btn__P3JXY {
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: var(--d-font-size) !important;
    min-width: 100px !important;
    text-transform: capitalize !important;
}

.Login_title__EqXoZ {
    font-size: var(--m-font-size);
    padding: 0 32px;
    width: calc(100% - 64px) !important;
    line-height: 1.5;
}

.Login_head__Cvf5j {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--semi-bold);
    padding: 16px 0;
}

.Login_head1__5Z4HY {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--medium-bold);
    padding: 20px 0;
    text-align: center;
}

.Login_emailinput__nQPwU {
    margin: 0 !important
}

.Login_center__gHNvT {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.Login_relative__2Rc_u {
    position: relative;
}

.Login_styledButton__UJsVN {
    width: 100%;
}

.Login_start_button__7cw2p {
    text-transform: capitalize !important;
    min-width: 150px !important;
    background-color: var(--yellow) !important;
}

.Login_head__Cvf5j {
    font-weight: 600;
    font-size: var(--h4-font-size);
    width: 100%;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 20px;
}

.Login_paper__sZFuQ {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 16px
}

@media screen and (min-width: 420px) {

.Login_paper__sZFuQ {
        border-radius: var(--border-radius);
        max-width: 60vw;
        min-width: 60vw;
        width: 60vw;
        padding: 0 !important;
        bottom: 15vh !important;
        margin: auto;
        height: 70vh !important
}
    }

@media screen and (max-width: 419px) {

.Login_verificationInput__yt5Qs {
        margin-bottom: 40%
}
    }
.Login_login__9bpLx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%
}
@media screen and (min-width: 420px) {
.Login_login__9bpLx {
        width: 400px
}
    }

.Login_verification__SuasN {
    display: flex;
    align-items: center;
    justify-content: space-between
}

@media screen and (min-width: 420px) {

.Login_verification__SuasN {
        justify-content: flex-start;
}

        .Login_verification__SuasN .Login_close__Yf9bV {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

.Login_verificationText__0d0XB {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--semi-bold);
    padding-top: 4px
}

@media screen and (min-width: 420px) {

.Login_verificationText__0d0XB {
        padding-left: var(--l-spacing);
        padding-top: 0
}
    }

.Login_posture__6RKwT {
    margin: 0 32px;
    width: calc(100% - 64px);
    height: 70%;
}

.Login_back__oyEhf {
    margin-right: var(--m-spacing);
}

.Login_logo__AFmW_ {
    margin-bottom: 45px;
}

.Login_submitButton__HyIiq {
    width: auto !important;
    min-width: 160px !important
}

@media screen and (max-width: 419px) {

.Login_submitButton__HyIiq {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100% !important;
        border-radius: 0 !important
}
    }

.Login_addButton__NGiJU {
    font-size: var(--h4-font-size) !important;
    width: 150px !important;
    padding: 16px 0 !important;
}

.Login_err__txMe_ {
    font-size: var(--m-font-size);
    text-align: center;
    padding-top: 12px;
    color: #FF4545;
}

.Login_input__Jp3Wc {
    width: 34px !important;
    height: 34px !important;
    outline: 0 !important;
    color: #32BA46;
    font-size: var(--h5-font-size);
    padding: 6px 8px;
    border: 1px solid #32BA46;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.Login_input__Jp3Wc[value=''] {
    border: 1px solid var(--black110) !important;
    border-radius: 4px;
    box-sizing: border-box;
}

.Login_focus__FbGWh {
    border: 1px solid #32BA46;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
}


.Login_conStyle__UcYGA {
    padding-top: 30px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

/* input:not([value=""]) */

.Login_timer__aQSc5 {
    font-weight: 500;
    font-size: var(--d-font-size);
    text-align: center;
    color: #FF4545;
    padding-top: 20px;
    padding-bottom: 12px;
}

.Login_resendCon__7gEib {
    font-size: var(--d-font-size);
    text-align: center;
    color: rgba(48, 48, 48, 0.5);
    padding-left: 8px;
}

.Login_resend__rKeUu {
    font-size: var(--d-font-size) !important;
    text-align: center !important;
    padding-left: 4px !important;
    font-weight: 500 !important;
    color: #32BA46 !important;
}

.Login_seperator__gb16x {
    border: 1px solid var(--black110);
}

.Login_divider__dTjzr {
    width: 1px;
    height: 35px;
    margin: 0px 30px;
    border: 1px solid var(--black110);
    box-sizing: border-box;
}

.Login_flex__hIcrW {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.Login_sub__inevn {
    padding-top: 20px;
    font-size: var(--d-font-size);
    text-align: center;
    color: #303030;
    opacity: 0.5;
}

.Login_hidden___9BQw {
    display: none !important;
}

.Login_heading__ZtbZg {
    text-align: center;
    font-size: var(--h4-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin-bottom: var(--m-spacing);
    margin-top: var(--s-spacing);
}

.Login_buttons__6u8OJ {
    display: flex;
    flex-direction: column;
}

.Login_buttons__6u8OJ .Login_button__A6kwL {
        border: 1px solid var(--black15);
        border-radius: var(--border-radius);
        padding: var(--m-spacing) var(--xl-spacing);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: var(--s-spacing) 0;
    }

.Login_buttons__6u8OJ .Login_button__A6kwL span:first-child {
            width: 30px;
            margin-right: var(--s-spacing);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

.Login_buttons__6u8OJ .Login_button__A6kwL {

        color: var(--black);
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
}



.Login_inputContainer__CEBGr {
    margin: 30px 0;
}

.Phone_main__cc_sT {
    border: 1px solid rgba(33, 33, 33, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
}
.Phone_customSelect__O6zXx {
    background: rgba(232,232,232, 0.3) !important;
    border-radius: 0px 0px 0px 0px !important;
    padding-top: 0px !important;
    border: 0 !important;
    font-weight: 500;
    font-size: 14px;
}
.Phone_customSelect__O6zXx .react-select__control {
        background: rgba(232,232,232, 0.3) !important;
    }
.Phone_textArea__sczsO {
    border: transparent !important;
    display: flex;
    border-radius: 0px 6px 6px 0px;
    align-items: center;
    align-content: center;
    padding-top: 20px;
    width: 54vw !important;
    padding-left: var(--s-spacing)
}
@media screen and (min-width: 420px) {
.Phone_textArea__sczsO {
        width: 195px !important
}
    }

.Phone_textArea__sczsO::-webkit-outer-spin-button,
.Phone_textArea__sczsO::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Phone_textArea__sczsO[type=number] {
    -moz-appearance: textfield;
}


.Phone_select__pIwDb {
    width: 40%;
    padding: var(--xs-spacing) 0px 0px 0px;
    background: rgba(232,232,232, 0.3) !important;

}

.Phone_inputMain__ZvRMO {
    width: 60%;
    display: flex;
}

.Phone_selectLabel__3qjai {
    background: rgba(232,232,232, 0.3) !important;
    font-weight: normal;
    font-size: 11px;
    padding-left: var(--s-spacing) !important;
}

.Phone_text-form-input__LWgiZ {
    border: none;
    resize: none;
}

.Phone_text-form-input__LWgiZ:focus {
    border: none;
    opacity: 1;
    outline: 0;
}

.Phone_text-form-input__LWgiZ::-moz-placeholder {
    color: var(--black);
    opacity: 0.2;
    font-size: var(--d-font-size);;
}

.Phone_text-form-input__LWgiZ:-ms-input-placeholder {
    color: var(--black);
    opacity: 0.2;
    font-size: var(--d-font-size);;
}

.Phone_text-form-input__LWgiZ::placeholder {
    color: var(--black);
    opacity: 0.2;
    font-size: var(--d-font-size);;
}

textarea.Phone_text-form-input__LWgiZ {
    font-family: inherit !important;
}

