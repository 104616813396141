.section {
    border-radius: 4px;
    width: calc(100%);
}

.width80 {
    width: calc(80%);
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.left {
    flex: 1;
    height: 100%;
    background: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 200px;
        object-fit: contain;
        max-height: 400px;
    }
}

.right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    @media (--desktop) {
        padding: 0 40px;
    }
}

.btn {
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: var(--d-font-size) !important;
    min-width: 100px !important;
    text-transform: capitalize !important;
}

.title {
    font-size: var(--m-font-size);
    padding: 0 32px;
    width: calc(100% - 64px) !important;
    line-height: 1.5;
}

.head {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--semi-bold);
    padding: 16px 0;
}

.head1 {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--medium-bold);
    padding: 20px 0;
    text-align: center;
}

.emailinput {
    margin: 0 !important
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.relative {
    position: relative;
}

.styledButton {
    width: 100%;
}

.start_button {
    text-transform: capitalize !important;
    min-width: 150px !important;
    background-color: var(--yellow) !important;
}

.head {
    font-weight: 600;
    font-size: var(--h4-font-size);
    width: 100%;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 20px;
}

.paper {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 16px;
    @media (--desktop) {
        border-radius: var(--border-radius);
        max-width: 60vw;
        min-width: 60vw;
        width: 60vw;
        padding: 0 !important;
        bottom: 15vh !important;
        margin: auto;
        height: 70vh !important;
    }
}

.verificationInput {

    @media (--phone) {
        margin-bottom: 40%;
    }
}
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (--desktop) {
        width: 400px;
    }
}

.verification {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (--desktop) {
        justify-content: flex-start;

        .close {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.verificationText {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--semi-bold);
    padding-top: 4px;

    @media (--desktop) {
        padding-left: var(--l-spacing);
        padding-top: 0;
    }
}

.posture {
    margin: 0 32px;
    width: calc(100% - 64px);
    height: 70%;
}

.back {
    margin-right: var(--m-spacing);
}

.logo {
    margin-bottom: 45px;
}

.submitButton {
    width: auto !important;
    min-width: 160px !important;
    @media (--phone) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100% !important;
        border-radius: 0 !important;
    }
}

.addButton {
    font-size: var(--h4-font-size) !important;
    width: 150px !important;
    padding: 16px 0 !important;
}

.err {
    font-size: var(--m-font-size);
    text-align: center;
    padding-top: 12px;
    color: #FF4545;
}

.input {
    width: 34px !important;
    height: 34px !important;
    outline: 0 !important;
    color: #32BA46;
    font-size: var(--h5-font-size);
    padding: 6px 8px;
    border: 1px solid #32BA46;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.input[value=''] {
    border: 1px solid var(--black110) !important;
    border-radius: 4px;
    box-sizing: border-box;
}

.focus {
    border: 1px solid #32BA46;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
}


.conStyle {
    padding-top: 30px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

/* input:not([value=""]) */

.timer {
    font-weight: 500;
    font-size: var(--d-font-size);
    text-align: center;
    color: #FF4545;
    padding-top: 20px;
    padding-bottom: 12px;
}

.resendCon {
    font-size: var(--d-font-size);
    text-align: center;
    color: rgba(48, 48, 48, 0.5);
    padding-left: 8px;
}

.resend {
    font-size: var(--d-font-size) !important;
    text-align: center !important;
    padding-left: 4px !important;
    font-weight: 500 !important;
    color: #32BA46 !important;
}

.seperator {
    border: 1px solid var(--black110);
}

.divider {
    width: 1px;
    height: 35px;
    margin: 0px 30px;
    border: 1px solid var(--black110);
    box-sizing: border-box;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.sub {
    padding-top: 20px;
    text-align: center;
    font-size: var(--d-font-size);
    text-align: center;
    color: #303030;
    opacity: 0.5;
}

.hidden {
    display: none !important;
}

.heading {
    text-align: center;
    font-size: var(--h4-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin-bottom: var(--m-spacing);
    margin-top: var(--s-spacing);
}

.buttons {
    display: flex;
    flex-direction: column;

    .button {
        border: 1px solid var(--black15);
        border-radius: var(--border-radius);
        padding: var(--m-spacing) var(--xl-spacing);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: var(--s-spacing) 0;

        span:first-child {
            width: 30px;
            margin-right: var(--s-spacing);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        color: var(--black);
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
    }
}



.inputContainer {
    margin: 30px 0;
}
