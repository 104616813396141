.main {
    border: 1px solid rgba(33, 33, 33, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
}
.customSelect {
    background: rgba(232,232,232, 0.3) !important;
    border-radius: 0px 0px 0px 0px !important;
    padding-top: 0px !important;
    border: 0 !important;
    font-weight: 500;
    font-size: 14px;

    :global(.react-select__control) {
        background: rgba(232,232,232, 0.3) !important;
    }
}
.textArea {
    border: transparent !important;
    display: flex;
    border-radius: 0px 6px 6px 0px;
    align-items: center;
    align-content: center;
    padding-top: 20px;
    width: 54vw !important;
    padding-left: var(--s-spacing);
    @media (--desktop) {
        width: 195px !important;
    }
}

.textArea::-webkit-outer-spin-button,
.textArea::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.textArea[type=number] {
    -moz-appearance: textfield;
}


.select {
    width: 40%;
    padding: var(--xs-spacing) 0px 0px 0px;
    background: rgba(232,232,232, 0.3) !important;

}

.inputMain {
    width: 60%;
    display: flex;
}

.selectLabel {
    background: rgba(232,232,232, 0.3) !important;
    font-weight: normal;
    font-size: 11px;
    padding-left: var(--s-spacing) !important;
}

.text-form-input {
    border: none;
    resize: none;
}

.text-form-input:focus {
    border: none;
    opacity: 1;
    outline: 0;
}

.text-form-input::placeholder {
    color: var(--black);
    opacity: 0.2;
    font-size: var(--d-font-size);;
}

textarea.text-form-input {
    font-family: inherit !important;
}
